import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="h1" components={components}>{`JUPP Live Domain Tests`}</MDXTag>
      <iframe src="http://127.0.0.1:8000" style={{
        "width": "400px",
        "height": "400px"
      }}></iframe>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    